/* ----------------------------------------------
 * Generated by Animista on 2022-2-5 19:53:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
 /* ----------------------------------------------
 * Generated by Animista on 2022-2-5 21:2:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-thru {
  0%, 10% {
    opacity: 0;
  }
  50% {
    opacity: .96;
  }
  90%, 100% {
    opacity: 0;
  }
}
@keyframes fade-thru {
  0%, 10% {
    opacity: 0;
  }
  50% {
    opacity: .96;
  }
  90%, 100% {
    opacity: 0;
  }
}
 @-webkit-keyframes kenburns-top {
  0% {

            -webkit-transform: scale(1.15) translateY(-15px);
            transform: scale(1.15) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
-webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;

  }
}
@keyframes kenburns-top {
  0% {

    -webkit-transform: scale(1.15) translateY(-15px);
    transform: scale(1.15) translateY(-15px);
-webkit-transform-origin: top;
    transform-origin: top;
}
100% {
-webkit-transform: scale(1) translateY(0);
transform: scale(1) translateY(0);
-webkit-transform-origin: 50% 16%;
transform-origin: 50% 16%;

}
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-17 20:17:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-in-hor-right {
	-webkit-animation: scale-in-hor-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-5 19:56:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top-left
 * ----------------------------------------
 */

 /* ----------------------------------------------
 * Generated by Animista on 2022-3-15 19:56:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

 @-webkit-keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}
@keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-5 21:11:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
 @-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-text-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            text-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-text-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
            text-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-text-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            text-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-text-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
            text-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-17 21:56:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}



.kenburns-top {
	-webkit-animation: kenburns-top 10s ease-out both ;
	        animation: kenburns-top 10s ease-out both ;
}

.kenburns-top-left {
	-webkit-animation: kenburns-top-left 35s ease-out both ;
	        animation: kenburns-top-left 35s ease-out both ;
}

.fade-in {
	-webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in2 {
	-webkit-animation: fade-in .6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in .6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-thru {
	-webkit-animation: fade-thru 1.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-thru 1.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-thru2 {
	-webkit-animation: fade-thru 1.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-thru 1.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.shadow-drop-2-center {
	-webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   letter-spacing: -0.4px;
  -webkit-font-smoothing: antialiased;
  background: transparent;
}

.sold {
  position: absolute;
  top: 0px;
  left: 0;
  color: white;
  font-size: 12pt;
  font-weight: 400;
  letter-spacing: .3px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 50%;
  text-align: left;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.904), transparent );
  z-index: 999;
  padding: 16px;
}

*::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: unset;
}

.main-body {
  margin-top: 0px;
  width: 100%;

  background: #ebdbcb;
  background: white;
  z-index: -1;
}

i {
  font-size: inherit;
  font-weight: inherit;
}

a.nav-btn {
  font-size: 16pt;
}

.pad-top-50 {
  padding-top: 0px;
}

.pad-top-20 {
  padding-top: 0px;
}

/* Navbar */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 100;
  background: transparent;
}

.back-white-nav {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 100;
}

.navbar-left {
  display: inline-flex;
  padding-left: 2%;
  padding-top: .5%;
  vertical-align: middle;
  width: 100%;
  text-transform: uppercase;
  font-weight: lighter;
}

.logo {
  font-weight: 100;
  font-size: 30pt;
  letter-spacing: -3.5px;
  color: transparent;
}

.navbar-right {
  padding-right: 0%;
  display: inline-block;
  position: fixed;
  top: 1.3%;
  left: 50%; -webkit-transform: translate(-35%); transform: translate(-35%);
  text-align: center;
  vertical-align: top;
  float: right;
  width: 90%;
}

.navbar-right-2 {
  padding-right: 0%;
  display: inline-block;
  position: fixed;
  top: 0%;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  text-align: center;
  vertical-align: top;
  float: right;
  width: 90%;
}

.less {
  height: 50px;
}

.nav-btn {
  display: inline-block;
  padding-right: 2%;
  padding-top: 1.5%;
  
}

.back-btn {
  position: relative;
  text-align: left;
  margin: 2%;
  margin-top: 5%;
  padding-top: 8px;
  padding-bottom: 5px;
  font-size: 8pt;
  font-weight: 400;
  opacity: 1;
  color: rgba(39, 39, 39, 0.637);
}

/* Main Site */

img {
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
}

img.front-page-1 {
  opacity: 1;
  display: block;
  overflow: hidden;
}

img.front-page-2 {
  opacity: 1;
  display: block;
  overflow: hidden;
}

.front-page-item {
  position: relative;
}

.image-overlay {
  position: absolute;
  height: 100%; width: 100%;
  top: 0; left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.425), rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.267));
  background: none;
  z-index: 1000;
}

.image-overlay-slogan {
  font-size: 58pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  font-weight: 200;
  top: 50%;
  line-height: 0.67em;
  text-align: center;
  letter-spacing: 4px;
  left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
}

.image-overlay-slogan-2 {
  font-size: 58pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  font-weight: 200;
  top: 60%;
  line-height: .67em;
  text-align: center;
  letter-spacing: 4px;
  left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
}

.image-overlay-text {
  font-size: 14pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  bottom: 5%;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
}

.trans-back {
  background-color: transparent;
}

.white {
  color: white;
}

.uppercase {
  text-transform: uppercase;
}

a.logo-link {
  position: absolute;
  z-index: 999;
  left: 1%;
  top: 9%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: lighter;
}


a.logo-link-2 {
  position: absolute;
  z-index: 999;
  left: 1%;
  top: -2%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: lighter;
}

.smaller {
  font-size: 8pt;
  margin-top: 10px;
  font-weight: 300;
  line-height: 1.6em;
  letter-spacing: -0.4px;
}

.bold {
  font-weight: 350;
}



.bolder {
  font-weight: 300;
  font-size: 12pt;
  opacity: .8;

  background: linear-gradient(to left, #ADA996, #F2F2F2, #DBDBDB, #EAEAEA);
  -webkit-background-clip: text;
}

.mobile-navbar-right {
  display: none;
}

.pad-20 {
  padding: 20px;
}

.main-page {
  text-align: center;
  background: #f5f5f5;
  background: white;
  padding-bottom: 40px;
  margin-top: 0;
}

.painting-image-case {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 2.5% auto;
  padding: 2%;

}


.painting-image-case-half {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 2.5% auto;
  padding: 2%;

}



.full-white-2 {
  background: white;
  width: 100%;
}


.full-white-back {
  position: relative;
  background: #f2f1ef;
  background: linear-gradient(to bottom, white, #f9f9f9);
  width: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
}

.painting-image-inside {
  padding-top: 0px;
  padding-bottom: 0px;
}

.painting-image {
  position: relative;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.image-series-box {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  -webkit-scrollbar: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

img.image-series {
  height: 200px;
  width: auto;
  margin: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.justify {
  text-align: justify;
}

.width-90 {
  width: 95%;
  margin: 0 auto;
}

.overflow-hidden {
  overflow: hidden; }

.opacity-90 {
  opacity: .9;
}

.mobile-navbar {
  display: none;
}

.desktop-front {
  width: auto;
  min-width: 100%;
  display: block;
  top: 0;
  min-height: 100vh;
  position: fixed;
  background: #ebdbcb;
  background: rgb(26, 26, 26);
  background: black;

}

.desktop-image {
  position: relative;
  min-width: 1280px;
  min-height: 100vh;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: block;
}

.desktop-image-2 {
  opacity: .85;
}


img.desktop-image {
  display: block;
  width: 100%;
  position: relative;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 0;
  min-width: 1280px;
  min-height: 100vh;
  animation: fade-thru 6.2s infinite;
  -webkit-animation: fade-thru 6.2s infinite;
}

img.mobile-image {
  display: none;
}



img.painting-image {
  position: relative;
  width: auto;
  height: 60vh;
  background: white;
  transition: .2s;
}

.detail-btn {

}

.font-14 {
  font-size: 14pt;
  font-weight: lighter;
  padding-top: 10px;
  line-height: 1em;
}

.op-6 {
  opacity: .7;
  margin-top: 20px;
  margin-bottom: -10px;
  font-weight: 200;
}

.abs-2 {
margin-top: 8%;
opacity: .7;
font-weight: 400;
font-size: 9pt;
}


.c {
  text-align: center;
}


.half {
  display: inline-block;
  min-width: 500px;
  width: 50%;
  vertical-align: middle;
}

.header-container {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  background: black;
  opacity: .3;
}

.header-title {
  position: fixed;
  z-index: 900;
  top: 30vh;
  opacity: 1;
  font-size: 70pt;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;

  letter-spacing: 40px;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
}


img.header-image {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
}


.l {
  text-align: center;
}

.r {
  text-align: center;
}

img.height-max {
 width: 90%;
 height: auto;

}

span.detail-btn {
  display: inline-block;
  width: 50%;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

span.detail-btn:hover {
  cursor: pointer;
}

.selected-btn {
  border-bottom: 1px solid black;
}

.show-full-page {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 80vh;
  z-index: 100;
}



video.work {

  height: 350px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
}

video.work2 {
  width: 50%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  
}

img.work {
  margin: 0 auto;
  position: relative;
  vertical-align: middle;
  height: 350px;
  width: auto;
  display: inline-block;
  margin: 0 auto;
}

img.work2 {
margin: 0 auto;
position: relative;
vertical-align: middle;
height: 350px;
width: auto;
display: inline-block;
margin: 0 auto;
}
img.work3 {
  margin: 0 auto;
  position: relative;
  vertical-align: middle;
  height: auto;
  width: 25%;
  display: inline-block;
  margin: 0 auto;
  }

.abs-3 {
  position: fixed;
margin-top: -24.5%;
left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);

opacity: .3;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 22pt;
font-weight: lighter;
}

  .nav-btn {
    display: inline-block;
    margin: 0 auto;
    font-size: 9pt;
    letter-spacing: -0.2px;
  }

  .img-cover {
    position: relative;
    width: 20%;
    display: inline-block;
  }

  img.artist-about-me {
    display: block;
    position: relative;

  }


  .inline-block {
    position: relative;
    display: inline-block;

    vertical-align: top;
  }

  .left {
    float: left;
    padding-right: 20px;
   }


  .right {
    float: right;
    padding: 20px;
    padding-right: 0;
   }

   img.width-55 {
    width: 100%;
  }


  img.width-40 {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  img.width-30 {
    padding-top: 0;
    width: 100%;
  }

  .banner {
    padding-top: 70px;
  }



  img.width-100 {
    width: 100%;
  }

  .row {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
  }

  .row-scroll {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;
  }

  .pad-top-0 {
    padding-top: 0;
  }

  .full {
    width: 100%;
  }

  .banner {
    position: fixed;
    width: 100%;
    padding-bottom: 0px;
    overflow: hidden;
    display: block;
  }

  img.banner-image {
    position: relative;
    width: 100%;
    display: block;
    opacity: 1;
  }

  img.banner-image-mobile {
    display: none;
  }

  .top-drop {
    padding-top: 580px;
  }

  .text-gray {
    color: rgba(39, 39, 39, 0.637);
  

    margin: 0 auto;
  }

  .text-gray-2 {
    color: rgb(32, 32, 32);
     }

  .italic {
    font-style: italic;
  }

  .width-600 {
    width: 600px;
    margin: 0 auto;
    margin-top: -20px;
  }

  .width-1000 {
    width: 1000px;
    margin: 0 auto;
  }

  .show-intro {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;
    transition: .4s;
    color: rgba(15, 15, 15, 0.397);
    font-weight: lighter;
    font-size: 30pt;
    letter-spacing: 10px;
    line-height: 1.2em;
    text-transform: capitalize;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }

.desktop-margin-top {
  padding-top: 50px;
}
img.work4 {
  margin: 0 auto;
  position: relative;
  vertical-align: middle;
  height: auto;
  width: 50%;
  display: inline-block;
  margin: 0 auto;
  }

  img.work5 {
    margin: 0 auto;
    position: relative;
    vertical-align: middle;
    height: auto;
    width: 25%;
    display: inline-block;
    margin: 0 auto;
    }

    .gray-back  {
      margin-top: 40px;
      background: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
    
    }

    .boldest {
      font-weight: lighter;
      letter-spacing: 0;
    }

    .black-back {
      background: black;
    }

    .blk-color {
      color: black;
      background-color: black;
      margin-top: 0;
    }

    .middle-bar {
      width: 100%;
      position: fixed;
      top: 70%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
      left: 50%;
      padding: 5px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      vertical-align: middle;
      z-index: 9;
      color: rgba(24, 24, 24, 0.911);
      text-align: center;
      background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.925), transparent)

    }

    span.detail-btn.fit-content {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 9.5pt;
    }


    .view {
      display: none;
    }

@media (max-width: 800px) {


  .view {
    display: block;
    text-transform: uppercase;
    text-align: left;
    padding: 0;
    margin: 0;
    padding-left: 10px;
    color: rgba(39, 39, 39, 0.637);

  }

  span.view-spn {
    margin-right: 5px;
    margin-left: 5px;
    padding-left: 2px;
    padding-right: 2px;
    padding: 2px;
  }

  .middle-bar {
    width: 100%;
    position: fixed;
    top: 70%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
    left: 50%;
    padding: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    vertical-align: middle;
    z-index: 9;
    color: rgba(24, 24, 24, 0.911);
    text-align: center;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.829), rgba(255, 255, 255, 0.925), rgba(255, 255, 255, 0.925), rgba(255, 255, 255, 0.829), transparent)

  }
  .smaller {
    font-size: 9pt;
    margin-top: 10px;
    font-weight: 300;
    line-height: 1.6em;
    letter-spacing: -0.4px;
  }

  .boldest {
    font-weight: 200;
    letter-spacing: 0;
  }

  .back-btn {
    position: relative;
    text-align: left;
    margin: 2%;
    margin-top: -2%;
    padding-top: 8px;
    padding-bottom: 5px;
    font-size: 8pt;
    font-weight: 400;
    opacity: 1;
    color: rgba(39, 39, 39, 0.637);
  }

  .gray-back  {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    background: linear-gradient(to right, #fafafa, #f7f7f7, #fafafa);
  
  }

  img.work3 {
    margin: 0 auto;
    position: relative;
    vertical-align: middle;
    height: auto;
    width: 100%;
    display: inline-block;
    margin: 0 auto;
    }

    img.work4 {
      margin: 0 auto;
      position: relative;
      vertical-align: middle;
      height: auto;
      width: 100%;
      display: inline-block;
      margin: 0 auto;
      }

      img.work4 {
        margin: 0 auto;
        position: relative;
        vertical-align: middle;
        height: auto;
        width: 50%;
        display: inline-block;
        margin: 0 auto;
        }

  .desktop-margin-top {
    padding: 0px;
  }
  

  img.banner-image {
    position: relative;
    width: 100%;
    display: none;

  }

  img.banner-image-mobile {
    position: relative;
    width: 100%;
    display: block;
  }

  .show-intro {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;
    transition: .4s;
    color: rgba(15, 15, 15, 0.438);
    background: white;
    font-weight: lighter;
    font-size: 20pt;
    letter-spacing: 10px;
    line-height: 1.2em;
    text-transform: capitalize;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }



  .full-white-back {
    position: relative;
    background: linear-gradient(to bottom, white, #f9f9f9);

    width: 100%;
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .nav-button-container {
    width: 43%;
    margin: 2%;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }

  img.nav-button {
    position: relative;
    width: 100%;
    border-radius: 13px;
  }

  video.nav-button {
    position: relative;
    width: 100%;
    border-radius: 13px;
  }


  .width-600 {
    width: 100%;
  }

  .width-1000 {
    width: 100%;
    margin: 0 auto;
  }
  img.width-55 {
    width: 100%;
  }


  img.width-40 {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  img.width-30 {
    padding-top: 0;
    width: 100%;
  }

  .banner {
    padding-top: 50px;
  }



  img.width-100 {
    width: 100%;
  }

  .full {
    width: unset;
  }



.bolder {
  font-weight: 300;
  font-size: 14pt;
  opacity: .8;

  background: linear-gradient(to left, #ADA996, #F2F2F2, #DBDBDB, #EAEAEA);
  -webkit-background-clip: text;
}


  .pad-top-50 {
    padding-top: 60px;
  }
  
  .pad-top-20 {
    padding-top: 50px;
  }

  .c {
    text-align: center;
  }

  video.work {
    width: 50%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
  }

  img.work {
      margin: 0 auto;
      position: relative;
      vertical-align: middle;
      width: 50%;
      height: auto;
      display: inline-block;
  }

  img.work2 {
    margin: 0 auto;
    position: relative;
    vertical-align: middle;
    width: 100%;
    height: auto;
    display: inline-block;
}

  video.work2 {
    width: 100%;
    height: auto;
    display: inline-block;
  }
  


  .header-container {
    position: relative;
    width: 100%;
    height: 20vh;
    overflow: hidden;
    background: white;
    opacity: .4;
  }
  
  .header-title {
    position: fixed;
    z-index: 900;
    top: 13vh;
    opacity: 1;
    font-size: 20pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    z-index: 9;
    letter-spacing: 20px;
    left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  }
  
  
  img.header-image {
    position: fixed;
    top: 100px; -webkit-transform: translate(0, -50%); transform: translate(0, -50%);
    left: 0;
  }
  

  img.painting-image {
    position: relative;
    width: 100%;
    height: auto;
    background: white;
    transition: .2s;
  }

  a.logo-link {
    position: absolute;
    z-index: 999;
    left: 1.5%;
    top: 10%;
  }

  .main-body {
    margin-top: 0px;
    width: 100%;
    overflow: hidden;
    background: #ebdbcb;
    background: white;
    z-index: -1;
  }

  img.image-series {
    height: 100px;
    width: auto;
    margin: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  

  .half {
    display: block;
    width: 100%;
    min-width: unset;
    vertical-align: top;
  }

  

  .desktop-image {
    min-width: 1150px;
    overflow: hidden;
    position: relative;
    display: none;
  }
  
  span.detail-btn {
    display: inline-block;
    width: 50%;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .selected-btn {
    border-bottom: 1px solid black;
  }

  span.detail-btn.fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
  }

  .main-page {
    text-align: center;
    
  }

  .painting-image {
    position: relative;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: auto;
    margin: 0;

  }

  .desktop-front {
    display: none;
  }

  .mobile-front {
    width: 100%;
    top: 0;
    height: 100vh;
    position: fixed;
    background: black;
  }

  img.mobile-image {
    display: block;
    height: 100%;
    width: auto;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 0;

    -webkit-animation: fade-thru 6.2s infinite;

            animation: fade-thru 6.2s infinite;
  }

  img.mobile-image-2 {
    height: 100%;
    width: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 0;
    opacity: .85;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
  }
  img.mobile-image-3 {
    height: 100%;
    width: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 0;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
  }
  img.mobile-image-4 {
    height: 100%;
    width: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 0;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
  }
  img.mobile-image-5 {
    height: 100%;
    width: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    z-index: 0;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
  }

  .mobile-image {
    -webkit-animation: fade-in;
            animation: fade-in;
  }
  

  .painting-image-case {
    position: relative;
    display: block;
    width: 96%;
  
    margin: 20px auto;

    vertical-align: top;

    padding: 0;

  }


  .painting-image-case-half {

    position: relative;
    display: inline-block;
    width: 45.5%;
  
    margin: 20px 5px;
padding: 0;
    vertical-align: top;
    vertical-align: top;


  }

  .navbar {
    height: 45px;
    padding-top: 1%;
  }

  .black {
    color: rgba(0, 0, 0, 0.938);
  }


  .navbar-left {
    padding-top: .5%;
  }

  .navbar-right {
    padding-top: .5%;
  }

  .logo {
    font-weight: lighter;
    font-size: 22pt;
    letter-spacing: -2.5px;
    color: transparent;
  }

  .image-overlay-text {
    font-size: 9pt;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    bottom: 10%;
    left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  
  }
  .image-overlay-slogan {
    font-size: 28pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  font-weight: 200;
  top: 50%;
  line-height: 0.67em;
  text-align: center;
  letter-spacing: 1px;
  left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
  }
  .image-overlay-slogan-2 {
    font-size: 28pt;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  font-weight: 200;
  top: 60%;
  line-height: .85em;
  text-align: center;
  letter-spacing: 2px;
  left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
  }

  .navbar-right {
    
    padding-right: 0%;
    display: none;
    text-align: right;
    vertical-align: middle;
    width: 68%;
  }

  .mobile-navbar-right {
    display: block;
    position: fixed;
    top: 1.1%; right: 0%;
    z-index: 100;

  }
  
  span.hamburger {
    display: block;
    position: relative;
    height: 1px;
    margin: 7.5px;
    border-radius: 9px;
    opacity: 1;
    width: 30px;
    transition: .4s;

  }

  .rotate-45l {
    -webkit-transform: rotate(-45deg) translate(-8px, 8px);
            transform: rotate(-45deg) translate(-8px, 8px); 
    transition: .4s;
    background-color: white;
  }

  .rotate-45r {
    -webkit-transform: rotate(45deg) translate(3px, 2px);
            transform: rotate(45deg) translate(3px, 2px);
    transition: .4s;
    background-color: white;
  }

  .mobile-navbar {
    display: block;
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    text-align: center;
    padding-top: 18%;
    font-size: 14pt;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 110%;
    height: 110vh;
    background-color: rgba(0, 0, 0, 0.932);
    text-align: center;
    padding-top: 25%;
    font-size: 14pt;
    -webkit-filter: blur(10px);
            filter: blur(10px)
  }

  .nav-btn {
    display: block;
    margin: 0 auto;
    padding: 6%;
    font-size: 10pt;
    letter-spacing: -0.2px;



  }

  .pad-20 {
    padding: 10px;
  }

  .font-14 {
    font-size: 10.5pt;
    font-weight: 300;
    padding-top: 10px;
    line-height: 1em;
  }

  .op-6 {
    opacity: .7;
    margin-top: 20px;
    margin-bottom: -7px;
    font-weight: 200;
    font-size: 10.5pt;
  }
  
  .abs-2 {
  margin-top: 3%;
  opacity: .8;
  font-weight: 500;
  font-size: 9.5pt;
  }
  

  .abs-3 {
    position: fixed;
  margin-top: -24%;
  left: 50%; -webkit-transform: translate(-50%); transform: translate(-50%);
  
  opacity: .3;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 22pt;
  font-weight: lighter;
  }

  .top-drop {
    padding-top: 220px;
    
  }

  .no-top-pad {
    padding-top: 0px;
  }


}


.white-gradient {
  background: linear-gradient(to right, white, white, white, rgb(194, 194, 194), white, white, white, white);
  color: white;
  background: transparent;

}

.black {
  background: linear-gradient(to right, rgb(0, 0, 0), black, black, rgb(165, 165, 165),black, rgb(165, 165, 165), black, black, rgb(7, 7, 7));
  color: black;
  background: transparent;
}


.white-back-gradient {
  background-color: white;


}

.black-back-gradient {
  background-color: black;
 
  background-color: rgba(39, 39, 39, 0.637);

}

.font-8 {
  font-size: 9pt;
  text-align: left;
  padding: 2px;
  opacity: .94;
}

.pad-left-8 {
  padding-left: 2%;
}

.inquire-btn {
  padding: 10px;
  border: 1px solid black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto;

}

.abs-50 { 
  position: absolute;
  left: 50%; top: 55%; 
  -webkit-transform: translate(-50%, -50%); 
          transform: translate(-50%, -50%);
  font-size: 14pt;
  font-weight: 300;


}

.bold-2 {
  font-weight: 300;
}

.gradient-text {
  background: linear-gradient(to left, #ADA996, #F2F2F2, #DBDBDB, #EAEAEA);
  -webkit-background-clip: text;
  color: white;
  text-shadow: 1px 1px 2px black;
  text-shadow: rgba(0, 0, 0, 0.699) 6px 0 10px;
  font-weight: 200;
}

.gradient-texta {
  background: linear-gradient(to left, #ADA996, rgba(242, 242, 242, 0.548), rgb(219, 219, 219), rgb(255, 255, 255));
  background: white;
  -webkit-background-clip: text;
  color: rgba(255, 255, 255, 0.438);
  text-shadow: 1px 1px 2px black;
  text-shadow: rgba(0, 0, 0, 0.699) 6px 0 10px;
  font-weight: lighter;
}

.gradient-textb {

  color: black;
  text-shadow: 1px 1px 2px black;
  text-shadow: rgba(0, 0, 0, 0.699) 6px 0 10px;
  font-weight: 200;
}

.gradient-text2 {
  background: linear-gradient(to left, #ADA996, #F2F2F2, #DBDBDB, #EAEAEA);
  -webkit-background-clip: text;
  color: white;
  text-shadow: 1px 1px 2px black;
  text-shadow: rgba(56, 55, 51, 0.767) 4px 0 10px;
  font-weight: 400;
  font-size: 9.5pt;
}

.text-black {
  color: #15120ff1;
  color: rgba(39, 39, 39, 0.829);
  font-size: 9.5pt;
  font-weight: 400;
  opacity: .7;
}

.blk-color {
  color: black;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
}

.pad-12 {
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
}

.hidden {
  display: none;
}

.pad-10 {
  padding: 10px;
}



.trans-back {
  background-color: transparent;
}

.hidden {
  display: none


}

